var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticClass:"desktop-logo",staticStyle:{"max-width":"200px"},attrs:{"fluid":"","src":require("@/assets/init/logo.svg"),"alt":"Logo"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto mobile",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-img',{staticClass:"mobile-logo",staticStyle:{"max-width":"200px","margin-bottom":"20px"},attrs:{"fluid":"","src":require("@/assets/init/logo.svg"),"alt":"Logo"}})],1),_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Seja bem-vindo ")]),_c('b-card-text',{staticClass:"mb-2"},[_c('b',[_vm._v(_vm._s(_vm.title))]),_vm._v(" é uma plataforma privada de conteúdo cientifíco de alto nível voltado para médicos oftamologistas. ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"username","vid":"username","rules":"required|alpha_spaces|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false:null,"placeholder":"Digite o seu nome"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"usuario@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-CRM","label":"CRM(Opcional)"}},[_c('validation-provider',{attrs:{"name":"crm","vid":"crm","rules":"integer|required_if:crmUf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-crm","type":"number","state":errors.length > 0 ? false:null,"name":"register-CRM","placeholder":"Digite o CRM"},model:{value:(_vm.crm),callback:function ($$v) {_vm.crm=$$v},expression:"crm"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.crm)?_c('b-form-group',{attrs:{"label-for":"register-Uf","label":"UF(Opcional)"}},[_c('validation-provider',{attrs:{"name":"crmUf","vid":"crmUf","rules":"required_if:crm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:_vm.crm > 0 ? 'is-invalid':null},[_c('b-form-select',{staticClass:"form-control-merge",attrs:{"id":"register-crmUf","type":"number","options":_vm.states,"state":!_vm.crmUf ? false:null,"name":"register-crmUf"},model:{value:(_vm.crmUf),callback:function ($$v) {_vm.crmUf=$$v},expression:"crmUf"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Senha"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|min:6|verify_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-repeat_password","label":"Repetir senha"}},[_c('validation-provider',{attrs:{"name":"repeatPassword","vid":"repeatPassword","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-repeatPassword","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-repeat_password","placeholder":"············"},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Eu concordo com a "),_c('b-link',{on:{"click":_vm.showModal}},[_vm._v(" política de privacidade e termos de uso ")])],1)],1),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-center",modifiers:{"modal-center":true}}],attrs:{"variant":"primary","block":"","type":"submit","disabled":((invalid) || (_vm.invalidCrm==false)) || !_vm.status}},[_vm._v(" Registar ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Já possui uma conta?")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v(" Faça login")])])],1),_c('b-modal',{ref:"modal-terms",attrs:{"centered":"","title":"Política de privacidade e termos de uso","ok-only":"","ok-title":"Concordo"}},[_c('ModalTerms')],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" ou ")])]),_c('div',{staticClass:"auth-footer-btn d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"facebook"},on:{"click":_vm.logInWithFacebook}},[_c('feather-icon',{attrs:{"icon":"FacebookIcon"}})],1),_c('GoogleLogin',{staticClass:"btn btn-google",attrs:{"params":_vm.params,"on-success":_vm.onSuccess}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }